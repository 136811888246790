/* providerPage.css */
@import './../../index.css';

.provider_background {
    background: linear-gradient(to right, var(--gradient-end-color), var(--gradient-start-color) 50%);
    border-color: #767E7C;
    border-right-style: solid;
    border-top-style: solid;
    border-bottom-style: solid;
    border-image: linear-gradient(to right, black, var(--border-color)) 1; 
}

.provider-page-container {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-gap: 1em;
    justify-items: stretch;
    align-items: stretch;
}

.provider-content-container {
    padding-right: 20px;
}

/* Media Queries for Responsiveness */

@media screen and (max-width: 1024px) {
    .provider-page-container {
        grid-template-columns: 30% 70%;
    }

    .provider-content-container {
        padding-right: 15px;
    }
}

@media screen and (max-width: 768px) {
    .provider-page-container {
        grid-template-columns: 100%;
    }

    .provider-content-container {
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media screen and (max-width: 480px) {
    .provider_background {
        border-right-style: none;
        border-image: none;
        border: 1px solid var(--border-color);
    }

    .provider-page-container {
        grid-gap: 0.5em;
    }

    .provider-content-container {
        padding-right: 5px;
        padding-left: 5px;
    }
}