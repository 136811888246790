.navbar {
  top: 0;
  width: 100%;
  background-color: #010201;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 1000;
  border-bottom: 2px solid #444449;
  height: 85px;
}

.navbar-brand .logo {
  height: 50px;
  width: 100px;
}

.logo:hover {
  filter: grayscale(100%)
}

.navbar-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin: 0 15px;
}

.navbar-links a {
  text-decoration: none;
  color: var(--text-color);
  font-weight: bold;
}

.navbar-links a:hover {
  color: var(--primary-color);
}

.wallet-button, .connect-wallet {
  padding: 0.8em 1.7em;
  background-color: #313A36;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid transparent;
  text-transform: uppercase;
  color: var(--text-color);
  width: 180px;
}

.wallet-button:hover, .connect-wallet:hover {
  border: 2px solid var(--primary-color);
}

.wallet-button:active, .connect-wallet:active {
  filter: brightness(0.8);
}

.content {
  margin-top: 80px;
  padding: 20px;
  width: 50px;
}

/* Media Queries for Responsiveness */

@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .navbar-brand {
    margin-bottom: 10px;
  }

  .navbar-links {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .navbar-links li {
    margin: 10px 0;
  }

  .wallet-button, .connect-wallet {
    width: 100%;
    margin-top: 10px;
  }
}

@media screen and (max-width: 480px) {
  .navbar-brand .logo {
    height: 40px;
    width: 80px;
  }

  .wallet-button, .connect-wallet {
    font-size: 12px;
    padding: 0.6em 1.2em;
  }
}