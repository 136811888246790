/* src/index.css */

/* Import the Inter font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

:root {
  --primary-color: #01FF85;
  --app-font-family: 'Inter', 'Arial', sans-serif;
  --background-color: #0b0a0a;
  --gradient-start-color: #0b0a0a;
  --gradient-end-color: #16382E;
  --font-family-body: 'Open Sans', sans-serif;
  --font-family-heading: 'Nunito', sans-serif;
  --link-color: #01FF85;
  --hover-border-color: #01FF85;
  --border-color: #494949;
  --text-color: #FFFFFF;

  /* Add responsive font sizes */
  --font-size-base: 16px;
  --font-size-large: 1.25rem;
  --font-size-small: 0.875rem;

  /* Add responsive spacing */
  --spacing-base: 1rem;
  --spacing-large: 2rem;
  --spacing-small: 0.5rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: var(--font-size-base);
}

body {
  font-family: var(--app-font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  background: linear-gradient(to bottom right, var(--gradient-end-color), var(--gradient-start-color) 35%);
  min-height: 100vh;
  line-height: 1.5;
}

#root {
  background: transparent;
}

/* Responsive typography */
h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.75rem; }
h4 { font-size: 1.5rem; }
h5 { font-size: 1.25rem; }
h6 { font-size: 1rem; }

/* Responsive images */
img {
  max-width: 100%;
  height: auto;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 1024px) {
  :root {
    --font-size-base: 15px;
    --spacing-large: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --font-size-base: 14px;
    --spacing-base: 0.875rem;
    --spacing-large: 1.25rem;
  }

  h1 { font-size: 2rem; }
  h2 { font-size: 1.75rem; }
  h3 { font-size: 1.5rem; }
  h4 { font-size: 1.25rem; }
  h5 { font-size: 1.1rem; }
  h6 { font-size: 1rem; }
}

@media screen and (max-width: 480px) {
  :root {
    --font-size-base: 13px;
    --spacing-base: 0.75rem;
    --spacing-large: 1rem;
  }

  h1 { font-size: 1.75rem; }
  h2 { font-size: 1.5rem; }
  h3 { font-size: 1.25rem; }
  h4 { font-size: 1.1rem; }
  h5 { font-size: 1rem; }
  h6 { font-size: 0.9rem; }
}