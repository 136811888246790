@import './../../index.css';

.mmor-card {
    backdrop-filter: blur(10px);
    box-shadow: 0 .4rem .8rem #0005;
    border-radius: .8rem;
    overflow: hidden;
    border-image: linear-gradient(to right, transparent, var(--border-color)) 1;
    background: linear-gradient(to right, var(--gradient-end-color), black);
    margin-left: 10px;
    margin-top: 20px;
    height: 220px;
    position: relative;
    padding: 20px;
}

.mmor-card-title {
    color: #ffffff;
    font-size: x-large;
    font-weight: bold;
    margin-bottom: 15px;
}

.mmor-amount {
    color: #01FF85;
    font-weight: bolder;
    font-size: large;
    position: absolute;
    left: 20px;
    right: 20px;
    top: 70px;
}

/* Media Queries for Responsiveness */

@media screen and (max-width: 1024px) {
    .mmor-card {
        height: 200px;
        padding: 15px;
    }

    .mmor-card-title {
        font-size: large;
    }

    .mmor-amount {
        font-size: medium;
        top: 60px;
    }
}

@media screen and (max-width: 768px) {
    .mmor-card {
        height: 180px;
        margin-left: 5px;
        margin-top: 15px;
    }

    .mmor-card-title {
        font-size: medium;
    }

    .mmor-amount {
        font-size: small;
        top: 50px;
        left: 15px;
        right: 15px;
    }
}

@media screen and (max-width: 480px) {
    .mmor-card {
        height: auto;
        min-height: 150px;
        margin-left: 0;
        margin-top: 10px;
        padding: 10px;
    }

    .mmor-card-title {
        font-size: small;
        margin-bottom: 10px;
    }

    .mmor-amount {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        font-size: x-small;
    }
}